import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./style.scss";
import {
  getPage,
  TypeComposableWebPage,
  TypeComposableWebQueryResult,
} from "../../utils/queries/composable_web";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import Linked from "../../utils/linkedItem";
import { AmazeeIOButton } from "../../components/button/button";
import usePreviewData from "../../utils/usePreviewData";
import { ResourceInteruption } from "../../templates/resources/customers";

const query = graphql`
  {
    allPrismicComposableWeb {
      edges {
        node {
          data {
            page_meta_description
            section_subtitle {
              html
              raw
              text
            }

            section_title
            title {
              html
              raw
              text
            }
            meta_title
            hero_image {
              alt
              url
            }

            subtitle {
              html
              raw
              text
            }

            services {
              service_title
              service_description
              service_image {
                alt
                url
              }
            }

            page_meta_thumbnail {
              url
              alt
            }
          }
        }
      }
    }
  }
`;

const ComposableWebPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeComposableWebQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicGlobalCdnWaf",
    true
  ) as TypeComposableWebPage;
  const title = result.meta_title ? result.meta_title : result.title.text;

  return (
    <AmazeePage location={location}>
      <div id="composable-web">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={RichTextRender(result?.title?.text)}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result?.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={RichTextRender(result?.title)}
          image={result?.hero_image}
          subheader={RichTextRender(result?.subtitle)}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="introduction">
          <div className="container">
            <div className="row flex justify-center items-center">
              <div className="col-12 col-lg-5 text-left">
                <img src={result?.image?.url} alt={result?.image?.alt} />
              </div>
              <div className="col-12 col-lg-5 text-left">
                <div>{RichTextRender(result?.section_text)}</div>
              </div>
            </div>
            <div className="col-12 col-lg-10"></div>
          </div>
        </section>

        <section id="services">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-8 text-center">
                <h3>{RichTextRender(result?.section_title)}</h3>
              </div>
              <div className="col-12 col-lg-8 text-center">
                <h6>{RichTextRender(result?.section_subtitle)}</h6>
              </div>
            </div>

            <div className="row">
              {result.services.map((service, index) => (
                <div key={index} className="col-12 col-lg-4 service">
                  <img
                    src={service?.service_image?.url}
                    alt={service?.service_image?.alt}
                  />
                  <div className="service-title">{service?.service_title}</div>
                  <div className="description">
                    {service?.service_description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="cal-section">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-6 text-left">
                <h2>Get a free composable web demo</h2>
                <h3>
                  See how we can help you build composable, scalable, and
                  flexible applications.
                </h3>
                <div className="text-section">
                  <p className="mb-4">
                    What to expect when completing our form:
                  </p>
                  <ul>
                    <li>You’ll book a time with our team straight away</li>
                    <li>
                      Get ready for a conversation tailored to your business and
                      use case
                    </li>
                    <li>
                      We’ll show you how our ZeroOps solution can maximize value
                      for your team, and business
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 text-center flex justify-center">
                <img src="https://images.prismic.io/amazeeio/2fdbb870-6caa-4653-8ee0-1b1b3e373da7_Book-a-Demo.png?auto=format,compress" />
              </div>
            </div>
            <iframe
              title={"hubspot"}
              style={{
                width: "100%",
                height: "750px",
              }}
              src={
                "https://go.amazee.io/meetings/martin-schloegl/zeroops-conversation?embed=true"
              }
            />
          </div>
        </section>

        {/* <section className="cta larger-text" id="cta">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-center flex-col items-center">
                                <h4><div className="cta-main container col-md-12 cta-big">{result.cta}</div></h4>
                                <h4><div className="container col-md-7 cta-big">{result.subtitle1}</div></h4>
                                <div className="buttons">
                                    <Linked link_to={result.button_link}>
                                        <AmazeeIOButton
                                            classes='btn-boldish amazeei-button inverted inline-block width-auto mt-4'>{result.button_text}</AmazeeIOButton>
                                    </Linked>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
      </div>
    </AmazeePage>
  );
};

const StaticTypeGlobalCDNWAFPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => <ComposableWebPage location={location} data={data} />}
    />
  );
};
export default StaticTypeGlobalCDNWAFPage;
