import { PrismicImage, PrismicPageDefinition, PrismicRichText } from "./types";
import { PrismicRawResourceData } from "./resources";

export type TypeComposableWebService = {
  service_image: PrismicImage;
  service_title: string;
  service_description: string;
};

export type TypeComposableWebServiceLower = {
  service_image_lower: PrismicImage;
  service_title_lower: string;
  service_description_lower: string;
};

export type TypeComposableWebPage = {
  title: PrismicRichText;
  meta_title: string;
  subtitle: PrismicRichText;

  section_title: string;
  section_title1: string;
  section_title2: string;
  section_title_lower: string;
  section_subtitle_lower: PrismicRichText;

  section_subtitle: PrismicRichText;
  section_subtitle1: PrismicRichText;
  section_text: PrismicRichText;
  section_text1: PrismicRichText;

  cta: string;
  subtitle1: string;
  button_text: string;
  button_link: PrismicPageDefinition;
  second_button_text: string;
  second_button_link: PrismicPageDefinition;

  services: TypeComposableWebService[];
  services_lower: TypeComposableWebServiceLower[];

  hero_image: PrismicImage;
  image: PrismicImage;
  image1: PrismicImage;

  page_meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;
};

export type TypeComposableWebQueryResult = {
  allPrismicComposableWeb: {
    edges: Array<{
      node: { data: TypeComposableWebPage };
    }>;
  };
};

export const getPage = (
  data: TypeComposableWebQueryResult
): TypeComposableWebPage => {
  return data.allPrismicComposableWeb.edges[0].node.data;
};
